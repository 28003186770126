/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Farhan's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Farhan Ashraf Portfolio",
    type: "website",
    url: "http://farhanashraf.dev/",
  },
};

//Home Page
const greeting = {
  title: "Farhan Ashraf",
  logo_name: "farhanashraf",
  nickname: "mriceflame",
  subTitle:
    "Leveraging my background in computer science and passion for cybersecurity to drive innovation in DevSecOps.",
  resumeLink:
    "https://resume.farhanashraf.dev",
  portfolio_repository: "https://github.com/farhanashrafdev",
  githubProfile: "https://github.com/farhanashrafdev",
};

const socialMediaLinks = [

// /* Your Social Media Link */
  //  github: "https://github.com/mriceflame",
  //  linkedin: "https://www.linkedin.com/in/s4a/",
  //  gmail: "farhanashrafali30@gmail.com",
  //  gitlab: "https://gitlab.com/",
  //  facebook: "https://www.facebook.com/mriceflame/",
  //  twitter: "https://twitter.com/mriceflame",
  //  instagram: "https://www.instagram.com/mriceflame1/"

  {
    name: "Github",
    link: "https://github.com/farhanashrafdev",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/farhanashrafdev",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCGjjMUFNXSgW_jVUiI1c-Iw",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:mriceflame@protonmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/mriceflame",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/mriceflame/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/farhanashrafdev/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
   
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    }
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/farhanashrafali1/",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/mriceflame",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "https://codeforces.com/profile/mriceflame",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@farhanashrafali30",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/farhanashraf",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Bahria University",
      subtitle: "Bachelor's of Science in Computer Science",
      logo_path: "bulc_logo.png",
      alt_name: "BULC",
      duration: "2018 - 2022",
      descriptions: [
        "⚡ I developed a strong foundation in computer science principles, including algorithms, data structures, and programming languages.",
        "⚡ In addition to my coursework, I also participated in a number of extracurricular activities, including hackathons and coding clubs, where I gained hands-on experience solving real-world problems and collaborating with my peers. ",
        "⚡ I am eager to apply my knowledge and skills to the field of DevSecOps and continue learning and growing as a professional.",
      ],
      website_link: "https://www.bahria.edu.pk/bulc",
    },
  ],
};

const certifications = {
  certifications: [
    //{
    //   title: "Machine Learning",
    //   subtitle: "- Andrew Ng",
    //   logo_path: "stanford_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
    //   alt_name: "Stanford University",
    //   color_code: "#8C151599",
    // },
    // {
    //   title: "Deep Learning",
    //   subtitle: "- Andrew Ng",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "Data Science",
    //   subtitle: "- Alex Aklson",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    // {
    //   title: "Big Data",
    //   subtitle: "- Kim Akers",
    //   logo_path: "microsoft_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
    //   alt_name: "Microsoft",
    //   color_code: "#D83B0199",
    // },
    // {
    //   title: "Advanced Data Science",
    //   subtitle: "- Romeo Kienzler",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    // {
    //   title: "Advanced ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "DL on Tensorflow",
    //   subtitle: "- Laurence Moroney",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "Fullstack Development",
    //   subtitle: "- Jogesh Muppala",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
    //   alt_name: "Coursera",
    //   color_code: "#2A73CC",
    // },
    // {
    //   title: "Kuberenetes on GCP",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
    // {
    //   title: "Cryptography",
    //   subtitle: "- Saurabh Mukhopadhyay",
    //   logo_path: "nptel_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
    //   alt_name: "NPTEL",
    //   color_code: "#FFBB0099",
    // },
    // {
    //   title: "Cloud Architecture",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have gained experience in the tech industry through a range of work and internships, as well as through volunteering with leading companies such as Google, Huawei, AWS, and Microsoft. My work experience includes a python developer internship, and I am currently working as a DevSecOps engineer. These experiences have allowed me to develop a strong foundation in Problem-solving and critical thinking and have prepared me for a successful career in the tech industry.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "DevSecOps Engineer",
          company: "SystemsLTD",
          company_url: "https://www.systemsltd.com",
          logo_path: "systems_logo.png",
          duration: "August 2022 - PRESENT",
          location: "Lahore, Pakistan",
          description:
            "As a DevOps engineer at Systems Ltd, I implemented and maintained infrastructure, ensured system security, improved deployment processes, reduced downtime, increased system reliability and scalability, and maintained high security through the use of DevOps and security best practices, including implementing continuous integration and delivery with Jenkins and GitHub, creating and managing a Kubernetes cluster, automating infrastructure deployment and management with Terraform and AWS CloudFormation, and proactively monitoring and alerting with CloudWatch and SNS, resulting in significant contributions to the company. Also secured Frist Position in AWS Assesment taken after internal training organized by systems.",
          color: "#0879bf",
        },
        {
          title: "Python Developer",
          company: "Revamedia",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "June 2021 - September 2022",
          location: "Remote, Denmark",
          description:
            "As a python developer intern at Revamedia, I developed and maintained Python-based web scraping scripts, collaborated with the development team to design and implement new features, debugged and troubleshot code, participated in code reviews, and gained expertise in Python programming and software development principles.",
          color: "#9b1578",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Github Campus Expert",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "April 2022 - Present",
          location: "Lahore, Pakistan",
          description:
            "Campus Experts are student leaders that strive to build diverse and inclusive spaces to learn skills, share their experiences, and build projects together. They can be found across the globe leading in-person and online conferences, meetups, and hackathons, and maintaining open source projects.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Learn Student Ambassador",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Jan 2022 - Present",
          location: "Lahore, Pakistan",
          description:
            "Microsoft Learn Student Ambassadors are a group of students who promote the use of Microsoft products and services, including the Microsoft Learn online learning platform. They engage with their communities and participate in events and activities related to technology. In exchange, they receive training, resources, and opportunities to connect with other ambassadors and industry professionals. The program is designed to help students gain leadership and communication skills, build their resumes, and connect with like-minded students.",
          color: "#D83B01",
        },
        {
          title: "Google Developer Student Club lead",
          company: "Google Developer",
          company_url:
            "https://gdsc.community.dev/bahria-university-lahore-campus/",
          logo_path: "dsc_logo.png",
          duration: "Aug 2021 - Jun 2022",
          location: "Lahore, Pakistan",
          description:
            "Google Developer Student Club is a community platform for university students with an interest (or background) in technology. Regardless of what you are majoring in, if you have the curiosity and determination to take the plunge into the ocean that is tech, GDSC is where you belong. Under the umbrella of Google Developers, DSCs around the world try to solve real-world problems using tech resources. Moreover, these community groups serve as the bridge between theory and practice hence allowing students to smoothly transition into the industry after graduation.",
          color: "#0C9D58",
        },
        {
          title: "Mentor",
          company: "LA Hacks",
          company_url:
            "https://gdsc.community.dev/bahria-university-lahore-campus/",
          logo_path: "la_logo.jpg",
          duration: "Mar 2021 & Mar 2022",
          location: "Lahore, Pakistan",
          description:
            "La Hacks is Southern California's largest annual student-led hackathon, where participants come together to collaborate on and build innovative software and hardware projects in just 24-48 hours. Join us at UCLA's Pauley Pavilion for a weekend of learning, networking, and creativity!.",
          color: "#0C9D58",
        },
        {
          title: "Co-Lead/Co-Organizer",
          company: "AWS Community Pakistan/ AWS User Group lahore",
          company_url:
            "https://awscommunity.pk/",
          logo_path: "awsc_logo.jpg",
          duration: "Nov 2021 - Dec 2022",
          location: "Lahore, Pakistan",
          description:
            "As a former co-organizer of the AWS User Group Lahore, I was fortunate to be able to contribute to the growth and success of the community. During my tenure, we were able to grow from 1.3k to 6.5k members and organize a variety of events and activities for our members. One of my proudest achievements was securing $10,000 in monetary sponsorships for the AWS Community Day 2022 Lahore event, which was a huge success and attracted over 600 AWS certified professionals and students. My skills in engaging with sponsors and partners and persuading them to support our group and our events were instrumental in securing these funds and helping to make the AWS Community Day 2022 Lahore a success. I am grateful for the opportunity to have served as a co-organizer and to have contributed to the growth and success of the AWS User Group Lahore. I wish the current team and the group continued success in the future.",
          color: "#0C9D58",
        },
        {
          title: "ChairPerson",
          company: "IEEE Student Branch BULC",
          company_url: "https://github.com/",
          logo_path: "ieee_logo.png",
          duration: "Oct 2018 - Jun 2022",
          location: "Lahore, Pakistan",
          description:
                "I was formerly the Branch Chair Person for the IEEE branch, where I was responsible for leading and organizing the team and overseeing all of our events and activities. Prior to this role, I served as the Events Team Head, where I was responsible for planning and executing events for the branch. I also held positions as Events Team Co-Head and Events Team Member, where I contributed to the planning and execution of events and assisted with the coordination of volunteers. My involvement with the IEEE branch began in April 2018, when I joined as a volunteer. During my tenure with the team, I gained valuable experience and developed a strong passion for supporting the growth and success of the IEEE branch. I was committed to providing opportunities for learning, networking, and professional development for our members until my departure in June 2022.", 
          color: "#181717",
        },
        {
          title: "Ambassador",
          company: "GitKraken",
          company_url: "https://www.gitkraken.com/",
          logo_path: "gitkraken.png",
          duration: "Aug 2021 - Present",
          location: "Lahore, Pakistan (remote)",
          description:
                    "",
          color: "#181717",
        },
        {
          title: "MLh LocalHost Organizer",
          company: "Major League Hacking",
          company_url: "https://mlh.io/",
          logo_path: "mlh_logo.png",
          duration: "Aug 2019 - Jan 2022 · 2 yrs 6 months",
          location: "Lahore, Pakistan",
          description:
                    "As a MLH Local Host organizer, I was responsible for organizing and running workshops and events on my campus to train students in the field of computer science and technology. I worked closely with the Major League Hacking (MLH) team to ensure that our events were well-planned and well-executed, and I helped to provide students with the resources and support they needed to succeed. During my time as a MLH Local Host organizer, I was able to train almost 200 students and help them develop valuable skills and knowledge in the field. I am passionate about supporting the growth and development of the next generation of technologists, and I am grateful for the opportunity to have served as a MLH Local Host organizer",
          color: "#181717",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Github,Google,CloudAcademy,Huawei,HackClub etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  // title: "Publications",
  // description:
  //   "I have worked on and published a few research papers and publications of my own.",
  // avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "farhan_ashraf.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blog.farhanashraf.dev",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Lahore 54000",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/RooSv7RidQoyprLt7",
  },
  phoneSection: {
    title: "Email me at",
    subtitle: "mriceflame@protonmail.com",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
